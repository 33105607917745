<template>
  <div class="d-flex align-center justify-center h-screen">
    <v-sheet
      class="mx-auto rounded-xl shadow-sm-xl px-8 pt-12 pb-16"
      width="450"
    >
      <h1>Sign in</h1>
      <p>to continue to VueTasks</p>
      <v-form
        fast-fail
        @submit.prevent="
          store.dispatch('auth/signIn', {
            email,
            password,
          })
        "
      >
        <v-text-field
          variant="underlined"
          v-model="email"
          :rules="required"
          label="Email"
        ></v-text-field>

        <v-text-field
          variant="underlined"
          v-model="password"
          type="password"
          :rules="required"
          label="Password"
        ></v-text-field>
        <router-link
          class="text-body-2 font-weight-regular"
          to="/forgot-password"
          >Forgot Password?</router-link
        >

        <v-btn type="submit" color="primary" block class="mt-2">Sign in</v-btn>
      </v-form>
      <div class="mt-2">
        <p class="text-body-2">
          Don't have an account?
          <router-link to="/sign-up">Sign Up</router-link>
        </p>
      </div>
    </v-sheet>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
const store = useStore();

const email = new ref("");
const password = new ref("");

const required = [
  (value) => {
    return !!value;
  },
];
</script>
