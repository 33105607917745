import { createApp } from "vue";
import { VueFire, VueFireAuth } from "vuefire";
import App from "./App.vue";
// the file we created above with `database`, `firestore` and other exports
import { firebaseApp } from "./firebase";
import "./styles/global.css";

const app = createApp(App);

app.use(VueFire, {
  // imported above but could also just be created here
  firebaseApp,
  modules: [
    // we will see other modules later on
    VueFireAuth(),
  ],
});

// Vuetify
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: "custom",
    themes: {
      custom: {
        dark: false,
        colors: {
          background: "#ffffff",
          surface: "#ffffff",
          primary: "#4a9eff",
          "primary-darken-1": "#368efd",
          "primary-darken-2": "#3780ee",
          "primary-darken-3": "#366dda",
          "primary-lighten-1": "#67b0ff",
          "primary-lighten-2": "#92c6ff",
          secondary: "#ffab4a",
          error: "#B00020",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FB8C00",
        },
      },
    },
  },
});

app.use(vuetify);

// Vue-Router
import router from "./router";
app.use(router);

// Vuex
import store from "./store";
app.use(store);

app.mount("#app");
