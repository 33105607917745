<template>
  <AppBar />
  <div class="my-16 pt-sm-8 pb-sm-8">
    <v-sheet
      class="mx-auto rounded-xl shadow-sm-xl px-2 px-sm-8 mt-sm-8 pt-4 pb-8 w-100"
      style="max-width: 700px; max-height: 50%"
    >
      <h1 class="text-h3 text-center d-none d-sm-block">Tasks</h1>
      <section class="">
        <div v-if="!userData?.todos.length" class="d-flex justify-center pt-12">
          <span class="text-h6 font-weight-light">
            Press the + button to add your first tasks
          </span>
        </div>
        <div
          v-for="(todo, index) in userData?.todos.sort(
            (a, b) => b.done - a.done
          )"
          :key="index"
        >
          <v-text-field
            type="text"
            :readOnly="todo.done"
            v-model="todo.title"
            :class="{ done: todo.done }"
            variant="outlined"
            density="compact"
            placeholder="What do you need to do?"
            aria-label="Task Description"
            @blur="update()"
          >
            <template v-slot:prepend>
              <v-checkbox-btn
                v-model="todo.done"
                aria-label="Set task to done or to-do"
                @click="(todo.done = !todo.done), update()"
              />
            </template>
            <template v-slot:append>
              <v-btn
                icon="mdi-delete"
                color="error"
                variant="plain"
                class="ml-0"
                aria-label="Delete this tasks"
                @click="remove(index)"
              />
            </template>
          </v-text-field>
        </div>
      </section>
    </v-sheet>
    <v-btn
      icon="mdi-plus"
      size="large"
      color="primary"
      elevation="8"
      class="mt-n8"
      style="position: fixed; right: 48px; bottom: 64px"
      aria-label="Add a new tasks"
      @click="add()"
    />
  </div>
</template>
<script setup>
import { useFirestore, useDocument, useCurrentUser } from "vuefire";
import { doc, setDoc } from "firebase/firestore";
import AppBar from "@/components/AppBar.vue";

const db = useFirestore();
const user = useCurrentUser();
const userDataRef = doc(db, "users", user.value.uid);
const userData = useDocument(userDataRef).data;

const update = async () => {
  setDoc(
    userDataRef,
    {
      todos: userData.value.todos.filter((todo) => todo.title !== ""),
    },
    { merge: true }
  );
};

const remove = async (index) => {
  userData.value.todos.splice(index, 1);
  update();
};

const add = async () => {
  if (!userData.value) {
    userData.value = {
      todos: [],
    };
  }
  if (!userData.value.todos) {
    userData.value.todos = [];
  }
  userData.value.todos.push({ done: false, title: "" });
};
</script>

<style>
.done {
  opacity: 0.45;
}
</style>
