<template>
  <div class="d-flex h-screen justify-center">
    <div class="d-flex w-100 flex-column align-center justify-center px-4">
      <h1 class="w-auto text-lg-h1 text-md-h2 font-weight-bold">
        Welcome to VueTasks
      </h1>
      <p class="mt-6 text-md-h5 font-weight-medium">
        Create, manage, and track your tasks.
      </p>
      <v-btn
        color="primary"
        to="/sign-in"
        size="x-large"
        class="text-h6 font-weight-medium mt-12 px-16 rounded-lg"
      >
        Get Started
      </v-btn>
    </div>
  </div>
  <footer>© 2023 Fabian Szabo. All rights reserved.</footer>
</template>
<script setup></script>
