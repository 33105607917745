<template>
  <v-overlay v-model="drawer" style="z-index: 7"></v-overlay>
  <v-layout>
    <v-navigation-drawer
      class="border-none"
      temporary
      v-model="drawer"
      location="right"
      :width="255"
    >
      <div
        class="d-flex flex-column align-center h-50 justify-end"
        style="
          background: rgb(54, 109, 218);
          background: linear-gradient(
            135deg,
            rgba(54, 109, 218, 1) 0%,
            rgba(74, 158, 255, 1) 100%
          );
          color: white;
        "
      >
        <AppLogo
          icon-only="true"
          size="96px"
          font-size="5em"
          elevated="true"
        ></AppLogo>
        <span class="text-h4 my-2">VueTasks</span>
      </div>
      <v-list
        class="d-flex flex-column justify-end h-50"
        density="comfortable"
        nav
        :width="255"
      >
        <v-btn
          v-for="(item, index) in items"
          :key="index"
          class="w-100 my-2"
          :style="{
            color:
              router.currentRoute.value.path === item.path ? '#4a9eff' : '',
          }"
          variant="text"
          size="large"
          @click="item.onClick"
          :prepend-icon="item.icon"
        >
          <span style="width: 180px; text-align: left">{{ item.title }}</span>
        </v-btn>
      </v-list>
    </v-navigation-drawer>
  </v-layout>
  <div class="top-nav">
    <AppLogo size="42px" font-size="2em"></AppLogo>
    <v-btn
      size="large"
      variant="plain"
      icon="mdi-menu"
      @click.stop="drawer = !drawer"
    />
  </div>
</template>
<script setup>
import { useStore } from "vuex";
import AppLogo from "@/components/AppLogo.vue";
import { ref } from "vue";
import router from "@/router";
const store = useStore();
const drawer = ref();
const items = [
  {
    title: "Tasks",
    icon: "mdi-file-tree",
    path: "/tasks",
    onClick: () => {
      router.push("/tasks");
    },
  },
  {
    title: "Account",
    icon: "mdi-cog",
    path: "/account",
    onClick: () => {
      router.push("/account");
    },
  },
  {
    title: "Sign out",
    icon: "mdi-logout",
    path: "/sign-out",
    onClick: () => {
      store.dispatch("auth/signOut");
    },
  },
];
</script>
