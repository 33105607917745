<template>
  <div class="d-flex align-center justify-center h-screen">
    <v-sheet
      class="mx-auto rounded-xl shadow-sm-xl px-8 pt-12 pb-16"
      width="450"
    >
      <h1>Forgot password</h1>
      <p class="my-4">
        Enter your email and we'll send you a link to reset your password.
      </p>
      <v-form
        fast-fail
        class="my-4"
        @submit.prevent="
          store.dispatch('auth/sendPasswordResetEmail', {
            email,
          })
        "
      >
        <v-text-field
          variant="underlined"
          v-model="email"
          :rules="required"
          label="Email"
        ></v-text-field>
        <v-btn type="submit" color="primary" block class="mt-2">Send </v-btn>
      </v-form>
      <div class="mt-2">
        <p class="text-body-2">
          Password reset?
          <router-link to="/sign-in">Sign In</router-link>
        </p>
      </div>
    </v-sheet>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
const store = useStore();
const email = new ref("");

const required = [
  (value) => {
    return !!value;
  },
];
</script>
