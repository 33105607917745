import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, collection } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAar8AQpMO-_-NQLGIilfZ0SdiyLU1-aLo",
  authDomain: "vue-tasks-app.firebaseapp.com",
  projectId: "vue-tasks-app",
  storageBucket: "vue-tasks-app.appspot.com",
  messagingSenderId: "440683910081",
  appId: "1:440683910081:web:63da4bee52e9c236729540",
  measurementId: "G-6740GKV6TH",
};

export const firebaseApp = initializeApp(firebaseConfig);

// used for the firestore refs
const db = getFirestore(firebaseApp);

// here we can export reusable database references
export const todosRef = collection(db, "todos");

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(firebaseApp);
