import {
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "@/firebase";
import router from "@/router";
const route = router.currentRoute;

const state = () => ({
  user: undefined,
});

const getters = {
  isSignedIn() {
    return !!state.user;
  },
};

const actions = {
  async signIn({ commit }, { email, password }) {
    const user = await signInWithEmailAndPassword(auth, email, password);
    if (user) {
      commit("setUser", user);
      helpers.redirect();
    }
  },
  async signOut({ commit }) {
    await signOut(auth);
    commit("setUser", undefined);
    router.push(router.afterSignOut);
  },
  async signUp({ commit }, { email, password }) {
    const user = await createUserWithEmailAndPassword(auth, email, password);
    if (user) {
      commit("setUser", user);
      helpers.redirect();
    }
  },
  async sendPasswordResetEmail(_, { email }) {
    await sendPasswordResetEmail(auth, email);
  },
};

const mutations = {
  setUser(state, value) {
    state.user = value;
  },
};

const helpers = {
  redirect: () => {
    const to =
      route.value.query.redirect &&
      typeof route.value.query.redirect === "string"
        ? route.value.query.redirect
        : router.afterSignIn;
    router.push(to);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
