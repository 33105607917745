import { createRouter, createWebHashHistory } from "vue-router";
import SignIn from "../views/SignInPage";
import ForgotPassword from "../views/ForgotPasswordPage";
import SignUp from "../views/SignUpPage";
import Home from "../views/HomePage";
import Tasks from "../views/TasksPage";
import Account from "../views/AccountPage";
import { getCurrentUser } from "vuefire";

const routes = [
  { path: "/", component: Home },
  { path: "/sign-in", component: SignIn, meta: { redirectIfAuthed: true } },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    meta: { redirectIfAuthed: true },
  },
  { path: "/sign-up", component: SignUp, meta: { redirectIfAuthed: true } },
  { path: "/tasks", component: Tasks, meta: { requiresAuth: true } },
  { path: "/account", component: Account, meta: { requiresAuth: true } },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.afterSignIn = "/tasks";
router.afterSignOut = "/";

router.beforeEach(async (to) => {
  // routes with `meta: { requiresAuth: true }` will check for the users, others won't
  if (to.meta.requiresAuth) {
    const currentUser = await getCurrentUser();
    // if the user is not logged in, redirect to the login page
    if (!currentUser) {
      return {
        path: "/sign-in",
        query: {
          // we keep the current path in the query so we can redirect to it after login
          // with `router.push(route.query.redirect || '/')`
          redirect: to.fullPath,
        },
      };
    }
  }

  if (to.meta.redirectIfAuthed) {
    const currentUser = await getCurrentUser();
    if (currentUser) {
      return router.afterSignIn;
    }
  }
});

export default router;
